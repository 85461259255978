import {Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  currencyOptions = ['INR', 'USD'];
  paymentCategoryOptions = ['Conference Registration Fees', 'WCISE Award Fees', 'Technical Workshop Fees'];
  category: string;
  normalfee = [];
  journalTypes = [];
  withoutjournalfee = [];
  presentationonlyfee = {catagory: '', categoryAmount: ''};
  selectedAmount: any = [];
  afternormalfee = [];
  afterwithoutjournalfee = [];
  afterpresentationonlyfee = {catagory: '', categoryAmount: ''};
  config = {};
  journalConfig = {};
  selectedJournalType: any = [];

  public orderForm: any = {};
  public payuform: any = {};
  disablePaymentButton: boolean = true;

  encRequest: String;
  accessCode: String;
  merchantId: string;
  workingkey: string;

  amount: string;
  billing_email: string;
  currency: string;
  name: string;
  paper_id: string;


  @ViewChild('form') form: ElementRef;
  @ViewChild('payForm') payForm: ElementRef;

  paypal = {catagory: 'scopus'};

  constructor(private httpClinet: HttpClient) {


  }


  paynow() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/html, application/xhtml+xml, */*',
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      responseType: 'text'
    };

    this.orderForm = {
      'amount': this.amount,
      'billing_email': this.billing_email,
      'currency': this.currency,
      'billing_name': this.name,
      'order_id': this.paper_id,
      'merchant_id': this.merchantId,
      'access_code': this.accessCode
    };
   const queryString = Object.keys(this.orderForm).map(key => key + '=' + this.orderForm[key]).join('&');
    this.httpClinet.post('https://wcise-node.onrender.com/api/payment/encrypt', queryString, {
      headers: new HttpHeaders({
        'Accept': 'text/html, application/xhtml+xml, */*',
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      responseType: 'text'
    }).subscribe(
      data => {
        console.log('POST Request is successful ', data);
        this.encRequest = data;
        setTimeout(_ => this.form.nativeElement.submit());
      },
      error => {

        console.log('Error', error);

      }
    );

  }

  ngOnInit() {
    this.workingkey = '25A114914FF9F3AEE593D07B784A5809';
    this.accessCode = 'AVBB85GE58CB37BBBC';
    this.merchantId = '220138';

    this.httpClinet.get('../../assets/data/earlybird-registrationFee.json').subscribe(value => {
      this.normalfee = value['normalfee'];
      this.withoutjournalfee = value['withoutjournalfee'];
      this.presentationonlyfee = value['presentationonlyfee'];
    }, error1 => {
      console.log(error1);
    });

    this.httpClinet.get('../../assets/data/latebird-registrationFee.json').subscribe(value => {
      this.afternormalfee = value['normalfee'];
      this.afterwithoutjournalfee = value['withoutjournalfee'];
      this.afterpresentationonlyfee = value['presentationonlyfee'];
    }, error1 => {
      console.log(error1);
    });

    this.config = {
      'displayKey': 'catagory', // if objects array passed which key to be displayed defaults to description,
      'search': true
    };
    this.journalConfig = {
      'displayKey': 'journalName', // if objects array passed which key to be displayed defaults to description,
      'search': true
    };


    this.journalTypes = [{
      'journalType': 'scopasINR',
      'journalName': 'FOR SCOPUS INDEXED JOURNAL'
    }, {
      'journalType': 'webscienceINR',
      'journalName': 'WEB OF SCIENCE & UGC APPROVED JOURNALS'
    }];


  }


  confirmPayment() {
    const paymentPayload = {
      email: this.payuform.email,
      name: this.payuform.firstname,
      phone: this.payuform.phone,
      productInfo: this.payuform.productinfo,
      amount: this.payuform.amount
    };
    return this.httpClinet.post<any>('https://mighty-dawn-89015.herokuapp.com/payment/payment-details', paymentPayload).subscribe(
      data => {
        console.log(data);
        this.payuform.txnid = data.txnId;
        this.payuform.surl = data.sUrl;
        this.payuform.furl = data.fUrl;
        this.payuform.key = data.key;
        this.payuform.hash = data.hash;
        this.payuform.txnid = data.txnId;
        this.disablePaymentButton = false;
      }, error1 => {
        console.log(error1);
      });
  }


  changeValue(event) {
    console.log(event);
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-success-page',
  templateUrl: './success-page.component.html',
  styleUrls: ['./success-page.component.css']
})
export class SuccessPageComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }
 
  ngOnInit() {
    this.route.paramMap.subscribe((params: any) => {
      //this.id = params.params.id;
      console.log('Success callback response')
      console.log(params)
      //Do some logic with the id
    })
  }

}

import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppComponent} from './app.component';
import {SubmissionsComponent} from './submissions/submissions.component';
import {BackgroundComponent} from './commons/background/background.component';
import {HeadersComponent} from './commons/headers/headers.component';
import {HomeComponent} from './home/home.component';
import {RegisterComponent} from './register/register.component';
import {SpecialsessionsComponent} from './specialsessions/specialsessions.component';
import {PaymentpageComponent} from './paymentpage/paymentpage.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {FootersComponent} from './commons/footers/footers.component';
import {CallforpaperComponent} from './callforpaper/callforpaper.component';
import {CommitteeComponent} from './committee/committee.component';
import {KeynoteComponent} from './keynote/keynote.component';
import {SelectDropDownModule} from 'ngx-select-dropdown';
import {AppSanitize} from './commons/app.sanitize';

import {ModalModule} from 'ngx-bootstrap';
import {LocalCommitteComponent} from './local-committe/local-committe.component';
import {ThanksPageComponent} from './thanks-page/thanks-page.component';
import {ApprovedsessionsComponent} from './approvedsessions/approvedsessions.component';
import {SuccessPageComponent} from './success-page/success-page.component';
import {FailurePageComponent} from './failure-page/failure-page.component';
import {NextEventsComponent} from './next-events/next-events.component';
import {ConferencePicsComponent} from './conference-pics/conference-pics.component';
import {AboutUsComponent} from './about-us/about-us.component';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AwardsComponent} from './awards/awards.component';
import {ReadMorePopupComponent} from './commons/read-more-popup/read-more-popup.component';
import { OnlineTrainingComponent } from './online-training/online-training.component';


const appRoutes: Routes = [

  /* { path: '', component: HomeComponent },
   { path: 'home', component: HomeComponent },
   { path: 'submission',      component: SubmissionsComponent },
   { path: 'register',      component: RegisterComponent },
   { path: 'session',      component: SpecialsessionsComponent }*/


  {
    path: '',
    component: AppComponent,
    children: [
      {path: '', component: HomeComponent},
      {path: 'home', component: HomeComponent},
      {path: 'cfp', component: CallforpaperComponent},
      {path: 'committee', component: CommitteeComponent},
      {path: 'localcommittee', component: LocalCommitteComponent},
      {path: 'trainings', component: OnlineTrainingComponent},

      {path: 'submission', component: SubmissionsComponent},
      {path: 'register', component: RegisterComponent},
      {path: 'session', component: SpecialsessionsComponent},
      {path: 'approvedsessions', component: ApprovedsessionsComponent},
      {path: 'keynote', component: KeynoteComponent},
      {path: 'success', component: SuccessPageComponent},
      {path: 'failure', component: FailurePageComponent},
      {path: 'prevevents', component: NextEventsComponent},
      {path: 'conferencePics', component: ConferencePicsComponent},
      {path: 'about-us', component: AboutUsComponent},
      {path: 'awards/:id', component: AwardsComponent}


    ]
  }

];

@NgModule({
  declarations: [
    AppComponent,
    SubmissionsComponent,
    BackgroundComponent,
    HeadersComponent,
    HomeComponent,
    RegisterComponent,
    SpecialsessionsComponent,
    PaymentpageComponent,
    FootersComponent,
    CallforpaperComponent,
    CommitteeComponent,
    KeynoteComponent,
    LocalCommitteComponent,
    ThanksPageComponent,
    ApprovedsessionsComponent,
    SuccessPageComponent,
    FailurePageComponent,
    NextEventsComponent,
    ConferencePicsComponent,
    AboutUsComponent,
    AwardsComponent,
    ReadMorePopupComponent,
    AppSanitize,
    OnlineTrainingComponent

  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    SelectDropDownModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    RouterModule.forRoot(
      appRoutes,
      {

        // onSameUrlNavigation: 'reload'
      }
      // <-- debugging purposes only
    )
  ],
  providers: [],
  entryComponents: [ReadMorePopupComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
}


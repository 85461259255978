import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router'
import { from } from 'rxjs';

@Component({
  selector: 'app-next-events',
  templateUrl: './next-events.component.html',
  styleUrls: ['./next-events.component.css']
})
export class NextEventsComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  goToPics(eventName) {
    this.router.navigate(['/conferencePics'], { queryParams: { eventName: eventName } });
  }

}

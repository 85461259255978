import {Component, Input, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap';
import {Subject} from 'rxjs/Subject';

@Component({
  selector: 'app-read-more-popup',
  templateUrl: './read-more-popup.component.html',
  styleUrls: ['./read-more-popup.component.css']
})
export class ReadMorePopupComponent implements OnInit {

  @Input() award: any;

  public onClose: Subject<boolean>;

  constructor(private _bsModalRef: BsModalRef) {
    this.award = {};
  }

  public ngOnInit(): void {
    this.onClose = new Subject();
  }

  public onShow(award: any): void {
    this.award = award;
  }

  public onConfirm(): void {
    this.onClose.next(true);
    this._bsModalRef.hide();
  }

  public onCancel(): void {
    this.onClose.next(false);
    this._bsModalRef.hide();
  }

}

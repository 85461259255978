import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-headers',
  templateUrl: './headers.component.html',
  styleUrls: ['./headers.component.css']
})
export class HeadersComponent implements OnInit {

  selectedItem = 'home';

  constructor() {
  }

  ngOnInit() {

  }

  listClick(item: string) {
    this.selectedItem = item;
  }

}

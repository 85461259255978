import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router'
@Component({
  selector: 'app-conference-pics',
  templateUrl: './conference-pics.component.html',
  styleUrls: ['./conference-pics.component.css']
})
export class ConferencePicsComponent implements OnInit {

  eventName: string;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.eventName = this.route.snapshot.queryParams.eventName;
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-submissions',
  templateUrl: './submissions.component.html',
  styleUrls: ['./submissions.component.css']
})
export class SubmissionsComponent implements OnInit {

  content: string
  constructor() {
    //this.content="Due to the large number of papers expected for this conference, the committee only allows an author to present two papers.  The abstracts can be one-page long (200-400 words). The abstract include Problem Statement, Purpose of Study, Methods, Findings and Results, and Conclusions and Recommendations (These elements may need some adaptation in the case of discussion papers: Background, Purpose of Study, Sources of Evidence, Main Argument, and Conclusions). Please note that some elements are optional in abstracts.\n"
    this.content="Research papers are invited for submission in WCISE’23. All the full length accepted papers of WCISE will be published in the peer reviewed SCOPUS/Non-SCOPUS Journals and SCOPUS Indexed book chapater as per their registration category.";


  }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-specialsessions',
  templateUrl: './specialsessions.component.html',
  styleUrls: ['./specialsessions.component.css']
})
export class SpecialsessionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import {Component, EventEmitter, OnInit, Output, TemplateRef, ViewEncapsulation,ViewChild, ElementRef} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';

@Component({
  selector: 'app-keynote',
  templateUrl: './keynote.component.html',
  styleUrls: ['./keynote.component.css']
})

export class KeynoteComponent implements OnInit {

  keynoteDetails = [];
  str={};
  details="details";
  modalRef: BsModalRef;
  constructor(public modalService: BsModalService) { 
   

    this.str = {"1":"Dr. D. P. Kothari obtained his BE (Electrical) in 1967, ME(Power Systems) in 1969 and Ph.D. in 1975 from BITS, Pilani, Rajasthan. From 1969 to 1977, he was involved in teaching and development of several courses at BITS Pilani. Earlier Dr. Kothari served as Vice Chancellor, VIT, Vellore, Director in-charge and Deputy Director (Administration) as well as Head in the Centre of Energy Studies at Indian Institute of Technology, Delhi and as Principal, VRCE, Nagpur. He was visiting professor at the Royal Melbourne Institute of Technology, Melbourne, Australia, during 1982-83 and 1989, for two years. He was also NSF Fellow at Perdue University, USA in 1992. He also taught at Melbourne University Australia for one semester in 1989."
    + "\nDr. Kothari, who is a recipient of the most Active Researcher Award, has published and presented 812 research papers in various national as well as international journals, conferences, guided 50 Ph.D scholars and 68 M. Tech students, and authored 50 books in various allied areas. He has delivered several keynote addresses and invited lectures at both national and international conferences. He has also delivered 42 video lectures on YouTube with maximum of 40,000 hits"
    +"Dr. Kothari is a Fellow of the National Academy of Engineering (FNAE), Fellow of Indian National Academy of Science (FNASc), Fellow of Institution of Engineers (FIE), Fellow IEEE ,Hon. Fellow ISTE and Fellow IETE."
    +"\nHis many awards include the National Khosla Award for Lifetime Achievements in Engineering (2005) from IIT, Roorkee. The University Grants Commission (UGC), Government of India has bestowed the UGC National Swami Pranavandana Saraswati Award (2005) in the field of education for his outstanding scholarly contributions."
    +"He is also the recipient of the Lifetime Achievement Award (2009) conferred by the World Management Congress, New Delhi, for his contribution to the areas of educational planning and administration. Recently he received Excellent Academic Award at IIT Guwahati by NPSC-2014."};

    this.keynoteDetails.push(this.str);
    this.details
  
  }
  @ViewChild('details') elementView: ElementRef;
  ngOnInit() {
  }


  openModal(id){
    alert(id);
    this.modalService.show(this.elementView);

  }

}

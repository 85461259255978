import { Component, OnInit } from '@angular/core';
// var email 	= require("emailjs/email");
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  homeAboutUs: string
 // images =[];

  constructor() {

    this.homeAboutUs="The WCISE is an international conference where researchers and academicians will share their ideas, present research papers, participate in workshops, and other related activities. The conference provides international platform for students, scientists, professors, and industry professionals to interact about their innovations and outcomes in research fields of science and latest technologies around the world. There will be feature discussions by eminent personalities from academics and industries on contemporary advances in the field of science, materials, computer science, mechanical engineering, electrical and electronics engineering and technology. It is expected that more than 500 participants around the globe will share their knowledge by keynote lectures, and oral presentations. It is an excellent prospect for the emissaries from global universities and institutes to share their knowledge with world-class scientists."
  }

  ngOnInit() {
    /*this.images =[
      {'name':'../../assets/img/team/1.jpg'},
      {'name':'../../assets/img/team/2.jpg'},
      {'name':'../../assets/img/team/3.jpg'},
      {'name':'../../assets/img/team/4.jpg'}
    ];*/
  }

  // sendEmail() {
    
    
  //   var server 	= email.server.connect({
  //     user:    "username", 
  //     password:"password", 
  //     host:    "smtp.your-email.com", 
  //     ssl:     true
  //   });
    
  //   // send the message and get a callback with an error or details of the message that was sent
  //   server.send({
  //     text:    "i hope this works", 
  //     from:    "you <username@your-email.com>", 
  //     to:      "someone <someone@your-email.com>, another <another@your-email.com>",
  //     cc:      "else <else@your-email.com>",
  //     subject: "testing emailjs"
  //   }, function(err, message) { console.log(err || message); });

  // }

}

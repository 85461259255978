import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.css']
})
export class BackgroundComponent implements OnInit {

  days;
  hours ;
  minutes;
  seconds;
  intvl;
  images=[];
  constructor() {

  }

  ngOnInit() {
    this.images =[
      {'name':'../../assets/img/slide_1.jpg','className':'active'},
      {'name':'../../assets/img/slide_2.jpg','className':''},
      {'name':'../../assets/img/slide_3.jpg','className':''}
    ]

    const date = new Date("December 29, 2023 00:00:00").getTime();

    this.intvl = setInterval(() => {
      return this.timerClock(date);
    }, 1000);

  }

  timerClock(date){

    const now = new Date().getTime();

    const difference = date - now;

    this.days = Math.floor(difference / (1000 * 60 * 60 * 24));

    this.hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

    this.minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

    this.seconds = Math.floor((difference % (1000 * 60)) / 1000);

    if (difference < 0) {
      clearInterval(this.intvl);
    }
    return  this.seconds;
  }
}
